<template>
  <b-list-group-item>
    <div class="font-weight-bold text-primary">
      {{ itemTitle }}
    </div>
    <div>
      {{ itemValue }}
    </div>
  </b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ListItem',
  components: {
    BListGroupItem,
  },
  props: {
    itemTitle: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: true,
    },
  },
}
</script>
